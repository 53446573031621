import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

const ContactPage = props => (
  <Layout>
    <Helmet>
      <script type="application/ld+json">{`
    { "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Lou Guarini Plumbing & Heating",
    "legalName" : "Lou Guarini Plumbing & Heating",
    "url": "http://www.jerseycityplumbers.com",
    "foundingDate": "1985",
    "founders": [
    {
    "@type": "Person",
    "name": "Lou Guarini"
    } ],
    "address": {
    "@type": "PostalAddress",
    "streetAddress": "240 Woodward Ave",
    "addressLocality": "Rutherford",
    "addressRegion": "NJ",
    "postalCode": "07070",
    "addressCountry": "USA"
    },
    "contactPoint": {
    "@type": "ContactPoint",
    "contactType": "customer support",
    "telephone": "[+201-333-5314]",
    "email": "louguarini@louguarini.com"
    }}
  `}</script>
    </Helmet>
    <SEO
      title="Contact information for Lou Guarini Plumbing & Heating"
      description="Contact us today for any plumbing issues you may be experiencing in Jersey City."
    />
    <div class="container">
      <h1>Contact us today!</h1>
      <p>
        Don’t put off those important repairs that can end up costing you more
        money down the road. Set up an appointment and get your problems solved.
        Call or email us with any questions you might have. We're here for you!
      </p>
      <div class="row">
        <div class="col-sm">
          <div class="card">
            <Img
              fluid={props.data.plumbingTruck.childImageSharp.fluid}
              alt="plumbing truck jersey city"
            />

            <div class="card-body">
              <h5 class="card-title">Contact Info</h5>
              <p class="card-text">
                <ul class="list-unstyled">
                  <li>
                    <b>Lou Guarini Plumbing & Heating</b>
                  </li>
                  <li>
                    <a href="tel:2013335314">201-333-5314</a>
                  </li>
                  <li>
                    <a href="mailto:louguarini@louguarini.com">
                      louguarini@louguarini.com
                    </a>
                  </li>
                  <li>240 Woodward Ave, Rutherford, NJ 07070</li>
                  <li>NJ State Master Plumber License #6574</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="google-maps">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48385.971229409195!2d-74.10375031356276!3d40.71530426581546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c250d225bfafdd%3A0x249f013a2cd25d9!2sJersey%20City%2C%20NJ!5e0!3m2!1sen!2sus!4v1667047760726!5m2!1sen!2sus"
              width="400"
              height="300"
              frameborder="0"
              allowfullscreen=""
              title="Map of Lou Guarini Plumbing & Heating in Jersey City"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
export default ContactPage

export const truckImage = graphql`
  fragment truckImage on File {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export const pageQuery = graphql`
  query {
    plumbingTruck: file(
      relativePath: { eq: "plumbing-truck-jersey-city.jpg" }
    ) {
      ...truckImage
    }
  }
`
